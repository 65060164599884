import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import { WidgetResult } from "../types/Field";
import { AbstractForm, FormField } from "../types/FormVersion";
import { removeWidgetVersionNumber } from "../utils/stringUtil";
import WidgetError from "./widgets/WidgetError";
import { getCalculatedFieldId, WidgetComponents } from "../utils/formUtil";
import { ErrorBoundary } from "./ErrorBoundary";
import { Text } from "../storybook/components/Text/Text";
import { CustomerResourceProvider } from "../context/CustomerResourceContext";
import useAutoScrollToFocussedField from "../hooks/useAutoScrollToFocussedField";
import { UniqueFieldId } from "../types/SubmissionState";
import { Message } from "../storybook/components/Message/Message";
import { WidgetEntryProp } from "../types/Widget";
import useFieldState from "../state/useFieldState";
import WidgetHidden from "./widgets/WidgetHidden";
import useStateInitialized from "../state/useStateInitialized";
import useStateReadOnly from "../state/useStateReadOnly";

export type SubmissionFormData = Record<string, WidgetResult<unknown>>;

export type FormProps = {
  formVersion: AbstractForm;
  parentId?: UniqueFieldId;
  entryId?: string;
  submissionId: string;
};

const Form: FC<FormProps> = ({ formVersion, submissionId, entryId, parentId }) => {
  const { t } = useTranslation();
  const initialized = useStateInitialized();
  useAutoScrollToFocussedField(entryId);

  if (!initialized) {
    return <Message type="error" title={t("FORM_NOT_INITIALIZED_TITLE")} />;
  }

  return (
    <CustomerResourceProvider>
      {formVersion.fields.map((field) => {
        const uniqueFieldId = getCalculatedFieldId(field.uid, submissionId, entryId, parentId);
        const widgetId = removeWidgetVersionNumber(field.widget);

        return (
          <ErrorBoundary key={uniqueFieldId}>
            {(hasError) =>
              hasError ? (
                getErrorElement(widgetId, field)
              ) : (
                <WidgetHOC widgetId={widgetId} uniqueFieldId={uniqueFieldId} entry={{ id: entryId, parentId }} />
              )
            }
          </ErrorBoundary>
        );
      })}
    </CustomerResourceProvider>
  );
};

const getErrorElement = (widgetId: string, field: FormField<any>): JSX.Element => (
  <div className="border border-red-600 bg-red-100 p-2">
    <Text size="xs">{`Failed to render '${field.properties.label_text || "Unknown"}' (${widgetId})`}</Text>
  </div>
);

export default Form;

type WidgetHOCProps = {
  widgetId: string;
  uniqueFieldId: UniqueFieldId;
  entry?: WidgetEntryProp;
};

export const WidgetHOC: FC<WidgetHOCProps> = ({ widgetId, uniqueFieldId, entry }) => {
  const [fieldState, setFieldState] = useFieldState(uniqueFieldId);
  const readOnly = useStateReadOnly();
  const WidgetComponent = WidgetComponents[widgetId]?.component;
  if (isNil(WidgetComponent)) {
    return <WidgetError widgetId={widgetId} />;
  }
  if (isNil(fieldState)) {
    return <WidgetHidden />;
  }
  return <WidgetComponent fieldState={fieldState} setFieldState={setFieldState} entry={entry} readOnly={readOnly} />;
};
