import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRxQuery } from "rxdb-hooks";
import { useDebounceValue, useLocalStorage } from "usehooks-ts";
import useMiniSearch, { WithScore } from "../hooks/useMiniSearch";
import { fromPlatformIcon } from "../utils/iconUtil";
import { Submission } from "../types/Submission";
import useAuth from "../hooks/useAuth";
import { toHumanReadableDate } from "../utils/dateUtil";
import { createNewSubmission } from "../utils/submissionUtil";
import { Form } from "../types/Folder";
import EmptyContent from "./EmptyContent";
import { getNestedFieldData } from "../utils/searchUtil";
import { isUsable } from "../utils/formUtil";
import useNavigateAsync from "../hooks/useNavigateAsync";
import useFolders from "../hooks/useFolders";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import { Title } from "../storybook/components/Title/Title";
import { NavItem } from "../storybook/components/NavItem/NavItem";
import { IconName } from "../storybook/components/Icon/Icon";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import { SearchField } from "../storybook/components/SearchField/SearchField";
import { ChipOption, Chips } from "../storybook/components/Chips/Chips";
import useFocus from "../hooks/useFocus";
import { resolveTheme } from "../storybook/themes";
import useSubmissionCollection from "../hooks/useSubmissionCollection";

interface SearchDrawerProps {
  open: boolean;
  onClose: () => void;
  initialCategory: Category;
}

type Category = "FORMS" | "TASKS" | "DRAFTS" | "ALL";
type CategoryOptions = "form" | "submission";
type RecentSearch = {
  id: string;
  category: CategoryOptions;
};

const SEARCH_DEBOUNCE = 300;
const MAX_RESULTS = 5;
const MAX_RESULTS_SINGLE_CATEGORY = 50;
const MAX_HISTORY_RESULTS = 6;
const SUBMISSION_OPTIONS = {
  matchAllOnEmptyQuery: false,
  miniSearchOptions: {
    fields: ["form.name", "form.description", "description", "task.message"],
    extractField: (submission: Submission, fieldName: string): any => getNestedFieldData(submission, fieldName),
    idField: "id",
  },
};
const FORM_OPTIONS = {
  matchAllOnEmptyQuery: false,
  miniSearchOptions: {
    fields: ["meta.name", "meta.description"],
    extractField: (form: Form, fieldName: string): any => getNestedFieldData(form, fieldName),
    idField: "id",
  },
};
const SearchDrawer: FC<SearchDrawerProps> = ({ open, onClose, initialCategory }) => {
  const { t } = useTranslation();
  const [searchFieldRef, setFocus] = useFocus();
  const [searchHistory, setSearchHistory] = useLocalStorage<RecentSearch[]>("searchHistory", []);
  const CATEGORIES: ChipOption[] = [
    { kind: "icon", value: "ALL", icon: "MenuAlt3Icon", label: t("ALL") },
    { kind: "icon", value: "FORMS", icon: "DocumentIcon", label: t("FORMS") },
    { kind: "icon", value: "TASKS", icon: "CheckCircleIcon", label: t("TASKS") },
    { kind: "icon", value: "DRAFTS", icon: "PencilIcon", label: t("DRAFTS") },
  ];
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState<Category>(initialCategory);
  const [currentMaxAmount, setCurrentMaxAmount] = useState<number>(
    initialCategory === "ALL" ? MAX_RESULTS : MAX_RESULTS_SINGLE_CATEGORY,
  );
  const { customerId } = useAuth();
  const { data: folders } = useFolders();
  const submissionCollection = useSubmissionCollection();
  const forms = useMemo(() => {
    const allForms = (folders || []).flatMap((folder) => folder.forms) as Form[];
    return allForms.filter(isUsable);
  }, [folders]);
  const [debouncedQuery] = useDebounceValue<string>(query, SEARCH_DEBOUNCE);
  const navigate = useNavigateAsync();

  const submissionQuery = useMemo(
    () => submissionCollection?.find().where("status").eq("draft").where("customerId").eq(customerId),
    [submissionCollection, customerId],
  );
  const { result: submissions } = useRxQuery(submissionQuery);

  const getFormId = useCallback((entry: Form) => entry.id, []);
  const { result: formHits } = useMiniSearch<Form>(debouncedQuery, open, FORM_OPTIONS, getFormId, forms);

  const getSubmissionId = useCallback((entry: Submission) => entry.id, []);
  const { result: submissionHits } = useMiniSearch<Submission>(
    debouncedQuery,
    open,
    SUBMISSION_OPTIONS,
    getSubmissionId,
    submissions,
  );

  useEffect(() => {
    if (open && setFocus) {
      setFocus();
    }
  }, [open, setFocus]);

  const setLatestSearch = (selected: Form | Submission, categoryOption: CategoryOptions): void => {
    const latest: RecentSearch = {
      id: selected.id,
      category: categoryOption,
    };

    const foundIndex = searchHistory.findIndex((h) => h?.id === selected.id);
    if (foundIndex >= 0) {
      setSearchHistory((history) => {
        const filtered = history.filter((element) => element.id !== selected.id);
        return [latest, ...filtered];
      });
    } else {
      setSearchHistory((history) => [latest, ...history].slice(0, MAX_HISTORY_RESULTS - 1));
    }
  };

  const showMoreText = (label: string, categoryName: Category): JSX.Element => (
    <div className="flex justify-end pt-4 align-middle text-brand-500">
      <IconAndTextButton
        label={t("MORE_RESULTS_FOR", { label })}
        icon="ArrowRightIcon"
        iconAlign="right"
        data-testid={`show-more-${label}`}
        variant="transparentBrand"
        onClick={() => {
          setCurrentMaxAmount(MAX_RESULTS_SINGLE_CATEGORY);
          setCategory(categoryName);
        }}
      />
    </div>
  );

  const formResults = (showMoreLink: boolean = true): JSX.Element => (
    <>
      {formHits.length > 0 && (
        <Title as="h2" size="xs" className="mt-6 pl-4">
          {t("FORMS")}
        </Title>
      )}
      {formHits.map((form: WithScore<Form>) => getFormResultItem(form)).slice(0, currentMaxAmount)}
      {showMoreLink && formHits.length > 0 && showMoreText(t("FORMS"), "FORMS")}
      {!showMoreLink && formHits.length === 0 && (
        <EmptyContent title={t("NO_RESULTS")} description={t("NO_RESULTS_DESCRIPTION")} imgSrc="assets/empty.svg" />
      )}
    </>
  );

  const getFormResultItem = (form: WithScore<Form>): JSX.Element => (
    <NavItem
      className="py-1"
      key={form.item.id}
      icon={{
        name: fromPlatformIcon(form.item.meta.icon),
        theme: resolveTheme(form.item.meta.iconColor),
      }}
      label={form.item.meta.name}
      description={form.item.meta.description}
      onClick={async () => {
        setLatestSearch(form.item, "form");
        const submission = await createNewSubmission(form.item, customerId!, submissionCollection!);
        navigate(`/submissions/${submission.id}`);
      }}
    />
  );

  const getSubmissionItem = (submission: WithScore<Submission>): JSX.Element => (
    <NavItem
      className="py-1"
      icon={{
        name: fromPlatformIcon(submission.item.form.icon),
        showSubIcon: !!submission.item.task,
        theme: resolveTheme(submission.item.form.iconColor),
      }}
      key={submission.item.id}
      label={submission.item.form.name}
      description={submission.item.description ? submission.item.description : submission.item.form.description}
      comment={submission.item.task?.message}
      meta={[
        {
          icon: "PencilAltIcon",
          label: toHumanReadableDate(submission.item.updatedAt),
        },
        ...(submission.item.task?.dueDate
          ? [
              {
                icon: "CalendarIcon" as IconName,
                label: toHumanReadableDate(submission.item.task.dueDate),
              },
            ]
          : []),
      ]}
      onClick={() => {
        setLatestSearch(submission.item, "submission");
        navigate(`/submissions/${submission.item.id}`);
      }}
    />
  );

  const taskResults = (showMoreLink: boolean = true): JSX.Element => {
    const tasks = submissionHits
      .filter((submission) => !!submission.item.task)
      .map((submission) => getSubmissionItem(submission))
      .slice(0, currentMaxAmount);
    return (
      <>
        {tasks.length > 0 && (
          <Title as="h2" size="xs" className="mt-6 pl-4">
            {t("TASKS")}
          </Title>
        )}
        {tasks}
        {showMoreLink && tasks.length > 0 && showMoreText(t("TASKS"), "TASKS")}
        {tasks.length === 0 && !showMoreLink && (
          <EmptyContent title={t("NO_RESULTS")} description={t("NO_RESULTS_DESCRIPTION")} imgSrc="assets/empty.svg" />
        )}
      </>
    );
  };

  const draftResults = (showMoreLink: boolean = true): JSX.Element => {
    const drafts = submissionHits
      .filter((hit) => !hit.item.task)
      .map((submission) => getSubmissionItem(submission))
      .slice(0, currentMaxAmount);
    return (
      <>
        {drafts.length > 0 && (
          <Title as="h2" size="xs" className="mt-6 pl-4">
            {t("DRAFTS")}
          </Title>
        )}
        {drafts}
        {showMoreLink && drafts.length > 0 && showMoreText(t("DRAFTS"), "DRAFTS")}
        {!showMoreLink && drafts.length === 0 && (
          <EmptyContent title={t("NO_RESULTS")} description={t("NO_RESULTS_DESCRIPTION")} imgSrc="assets/empty.svg" />
        )}
      </>
    );
  };

  const recentSearches = useMemo(() => {
    const recent = searchHistory
      .filter((result) => result !== null)
      .filter((result) =>
        result.category === "form"
          ? forms.find((form) => form.id === result.id)
          : submissions?.find((submission) => submission.id === result.id),
      )
      .filter((result) => result)
      .map((result) =>
        result.category === "form"
          ? getFormResultItem({ item: forms.find((form) => form.id === result.id)! })
          : getSubmissionItem({
              item: submissions?.find((submission) => submission.id === result.id)!,
            }),
      );
    return (
      <>
        {recent.length > 0 && (
          <Title as="h2" size="xs" className="mt-6 pl-4">
            {t("RECENT_SEARCHES")}
          </Title>
        )}
        {recent}
      </>
    );
  }, [searchHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMaxResult = (results: WithScore<Form | Submission>[]): number => {
    const scores = results.map((result) => result.score).filter((i) => !!i) as number[];
    return Math.min(...scores);
  };

  const orderResults = (): JSX.Element => {
    const drafts = submissionHits.filter((sub) => !sub.item.task);
    const tasks = submissionHits.filter((sub) => sub.item.task);

    const order = [
      { category: "FORMS", results: formHits },
      { category: "DRAFTS", results: drafts },
      { category: "TASKS", results: tasks },
    ]
      .sort((a, b) => getMaxResult(a.results) - getMaxResult(b.results))
      .map((i) => i.category as Category);

    return (
      <>
        {order.map((i) => {
          switch (i) {
            case "TASKS":
              return <Fragment key="TASK_RESULT">{taskResults()}</Fragment>;
            case "DRAFTS":
              return <Fragment key="DRAFT_RESULT">{draftResults()}</Fragment>;
            case "FORMS":
              return <Fragment key="FORM_RESULT">{formResults()}</Fragment>;
            default:
              return undefined;
          }
        })}
      </>
    );
  };

  const drawerBody = (): JSX.Element | undefined => {
    if (query !== debouncedQuery) {
      return undefined;
    }
    return (
      <>
        {query.length === 0 && category && recentSearches}
        {category === "ALL" && query.length > 0 && orderResults()}
        {category === "FORMS" && query.length > 0 && formResults(!category)}
        {category === "TASKS" && query.length > 0 && taskResults(!category)}
        {category === "DRAFTS" && query.length > 0 && draftResults(!category)}
      </>
    );
  };

  return (
    <Drawer
      header={{
        kind: "simple",
        title: t("SEARCH"),
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
        content: (
          <div className="mx-5 pb-6">
            <SearchField
              className="mb-3 py-2"
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={query}
              onChange={(newQuery) => setQuery(newQuery)}
              focusableRef={searchFieldRef}
            />
            <Chips
              selected={category}
              onSelect={(value) => {
                value === "ALL" ? setCurrentMaxAmount(MAX_RESULTS) : setCurrentMaxAmount(MAX_RESULTS_SINGLE_CATEGORY);
                setCategory(value as Category);
              }}
              options={CATEGORIES}
            />
          </div>
        ),
      }}
      open={open}
      onClose={onClose}
      contentPadding={false}
    >
      {drawerBody()}
    </Drawer>
  );
};

export default SearchDrawer;
