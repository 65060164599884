import { useEffect, useState } from "react";
import { isNil } from "lodash-es";
import { FormVersion } from "../types/FormVersion";
import useAuth from "../hooks/useAuth";
import useDatabase from "../hooks/useDatabase";
import { FormState, SubmissionStoreOptions } from "./useSubmissionStore";
import { FormEngine } from "../utils/FormEngine";
import useDeviceInfo from "../hooks/useDeviceInfo";
import useStateInitialized from "./useStateInitialized";
import useActionSetDb from "./useActionSetDb";
import useActionInitialize from "./useActionInitialize";

const defaultOptions: SubmissionStoreOptions = { readOnly: false, persist: true, validate: true };

export const useSubmissionStoreInitializer = (
  submissionId?: string,
  formId?: string,
  formState?: FormState,
  formVersion?: FormVersion,
  options: SubmissionStoreOptions = defaultOptions,
): { initialized: boolean } => {
  const {
    username,
    authorization: { userId },
  } = useAuth();
  const { database } = useDatabase();
  const { id: deviceId } = useDeviceInfo();
  const initialize = useActionInitialize();
  const setDb = useActionSetDb();
  const initialized = useStateInitialized();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isNil(database)) {
      setDb(database);
    }
  }, [database, setDb]);

  useEffect(() => {
    if (loaded) {
      return; // prevent loading it more than once, like when submission is updated from draft to final
    }
    if (isNil(formState) || isNil(formVersion) || isNil(submissionId) || isNil(formId)) {
      return;
    }
    const formStateMatchesSubmission = isNil(formState.fields.find((f) => f.value.meta.submissionId === submissionId));
    if (formStateMatchesSubmission) {
      return; // because formState is created async, there's a short window where "formState" is outdated when switching submissionId (Save & New)
    }

    const { fieldProperties } = formVersion;
    const formEngine = new FormEngine(submissionId, formId, deviceId, formVersion, fieldProperties, username!, {
      validate: options.validate,
    });

    initialize(formVersion, formEngine, fieldProperties, submissionId, deviceId, formId, userId, options, formState);
  }, [formState, formVersion, submissionId, formId, username, options, initialize, deviceId, loaded, userId]);

  // This prevents a render loop of a form with an out-of-date store
  useEffect(() => {
    if (initialized) {
      setLoaded(true);
    }
  }, [initialized, setLoaded]);

  return { initialized: initialized && loaded };
};
