import { createElement, FC, useState } from "react";
import classNames from "classnames";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import useFileHandler from "../hooks/useFileHandler";
import { FileResult } from "../types/Widget";
import { decompress } from "../utils/compressUtil";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { noopAsync } from "../utils/noop";
import useStateSubmissionId from "../state/useStateSubmissionId";

interface TemplateContentProps {
  content: string;
  className?: string;
  allowNewLines?: boolean;
}

const TemplateContent: FC<TemplateContentProps> = ({ content, className, allowNewLines = false }) => {
  const [innerHtml, setInnerHtml] = useState("");
  const { t } = useTranslation();
  const { getFileUrl } = useFileHandler();
  const submissionId = useStateSubmissionId();

  // replace all `FILE:` segments in content by actual <img> elements
  // this is required for rendering Photos and Signatures in Entry Descriptions
  // NOTE:  This is not used in SubmissionListPage (for Draft/Task/Sent), so we filter out the `FILE:` items there.
  //        Maybe later, we add support for images in those description lines too
  useAsyncEffect(
    async () => {
      const replaceImages = async (input: string): Promise<string> => {
        const fileMatches = input.match(/FILE:H4s\S+/g);
        if (fileMatches) {
          const replacePromises = fileMatches.map(async (fileMatch) => {
            const compressedFileResult = fileMatch.replace("FILE:", "");
            const file = decompress<FileResult>(compressedFileResult);
            const fileUrl = await getFileUrl(file, submissionId);
            const fileElement = renderToString(
              createElement("img", {
                className: "mx-1 inline max-h-8 rounded-md border-1 border-gray-300",
                src: fileUrl,
                alt: t("USER_SUPPLIED_IMAGE"),
              }),
            );
            // eslint-disable-next-line no-param-reassign
            input = input.replace(fileMatch, fileElement);
          });
          await Promise.all(replacePromises);
        }
        return input;
      };

      setInnerHtml(await replaceImages(content));
    },
    noopAsync,
    [content],
  );

  return (
    <span
      className={classNames("line-clamp-4 break-words", className, { "whitespace-pre-wrap": allowNewLines })}
      dangerouslySetInnerHTML={{ __html: innerHtml }} // eslint-disable-line react/no-danger -- We mitigated the danger by sanitizing it first
    />
  );
};
export default TemplateContent;
