import { isEmpty, isNil } from "lodash-es";
import { deepEqual } from "fast-equals";
import { FieldState, UniqueFieldId } from "../types/SubmissionState";
import useSubmissionStore, { FormState } from "./useSubmissionStore";
import { FormVersion, WidgetProperties } from "../types/FormVersion";
import { Field, RememberedField, WidgetResult, WidgetResultMeta } from "../types/Field";
import { SubmissionFormData } from "../components/Form";
import { getCalculatedFieldId, getFieldFromFormVersions, getWidgetProperties } from "../utils/formUtil";
import { notEmpty } from "../utils/arrayUtil";
import logger from "../utils/logger";
import { findFormVersions } from "../utils/FormEngine";

type FieldWithRevision = Field & { revision?: string };

export const buildFormState = (
  formVersion: FormVersion,
  fields: FieldWithRevision[],
  submissionId: string,
  deviceId: string,
  formData: SubmissionFormData,
  rememberedFields: RememberedField[] = [],
): FormState => ({
  rememberedFields,
  description: "",
  fieldRevisions: fields.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.revision }), {}),
  fields: fields
    .sort((a, b) => a.order - b.order)
    .map((field): FieldState<WidgetProperties, WidgetResult<unknown>> | undefined => {
      const formField = getFieldFromFormVersions(findFormVersions(formVersion), field.formFieldId);
      if (isNil(formField)) {
        logger.error("Could not find field in FormVersion", null, {
          extra: { fieldId: field.formFieldId, submissionId, formVersionId: formVersion.id },
        });
        return undefined;
      }
      const uniqueFieldId = getCalculatedFieldId(formField.uid, submissionId, field.entryId, field.parentId);
      const properties = getWidgetProperties(formField.widget, formField.properties);
      const widgetResult = formData[uniqueFieldId];
      if (isNil(widgetResult)) {
        logger.error("Could not find field result (value) for uniqueFieldId", null, {
          extra: { uniqueFieldId, submissionId, formVersionId: formVersion.id },
        });
        return undefined;
      }
      return {
        uid: formField.uid,
        uniqueFieldId,
        deviceId,
        value: widgetResult,
        visible: !field.hidden,
        widget: formField.widget,
        error: field.error,
        deleted: false,
        properties,
      };
    })
    .filter(notEmpty),
});

export type UseFieldStateResult = [
  FieldState<WidgetProperties, WidgetResult<unknown>>,
  (rawValue?: unknown, meta?: Partial<WidgetResultMeta>) => void,
];

const useFieldState = (uniqueFieldId: UniqueFieldId): UseFieldStateResult => {
  const fieldState = useSubmissionStore(
    (store) =>
      store.formState.fields.find((x) => x.uniqueFieldId === uniqueFieldId) as FieldState<
        WidgetProperties,
        WidgetResult<unknown>
      >,
  );
  const updateField = useSubmissionStore((store) => store.updateField);

  const setFieldState = (rawValue: unknown | undefined, meta: Partial<WidgetResultMeta> = {}): void => {
    if (isEmpty(meta) && deepEqual(rawValue, fieldState.value.rawValue)) {
      return; // nothing changed, prevent unnecessary persist
    }
    const humanEdited = !isNil(meta.humanEdited) ? meta.humanEdited : true;
    updateField({
      ...fieldState,
      value: { ...fieldState.value, rawValue, meta: { ...fieldState.value.meta, ...meta, humanEdited } },
    });
  };

  return [fieldState, setFieldState];
};

export default useFieldState;
