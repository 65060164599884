import { isEmpty } from "lodash-es";
import { Duration } from "luxon";
import { DataSourceEntry } from "../types/Datasource";
import { traversePath } from "./interpolateUtil";
import { CatalogueResult } from "../components/widgets/WidgetCatalogue";
import { CurrencyResult, DurationResult } from "../types/Widget";
import { FormFieldTerm } from "./calculationUtil";
import { WidgetDataTypes, WidgetResult } from "../types/Field";
import { DateTimeValue } from "../storybook/components/DateTimeInput/DateTimeInput";
import { FieldState } from "../types/SubmissionState";
import { WidgetProperties } from "../types/FormVersion";
import { removeWidgetVersionNumber } from "./stringUtil";

const excludedTermTypes: WidgetDataTypes[] = ["file", "none", "location", "array"];
export const getTermFields = (
  fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  terms: string[],
  entryId?: string,
): FormFieldTerm[] => {
  const subformWidgets = fields.filter(
    (value) => !isEmpty(value.value.entries) && value.value.meta.entryId === entryId,
  );

  return fields
    .filter((field) => findTerm(terms, field))
    .filter((field) => field.value.meta.entryId === entryId || isNestedField(field, subformWidgets))
    .filter((field) => !field.deleted)
    .map((field) => ({ field, term: findTerm(terms, field) }) as FormFieldTerm);
};

export const getTermData = ({ field: f, term }: FormFieldTerm): unknown[] => {
  if (!f.value.rawValue && f.value.rawValue !== 0 && f.value.rawValue !== "") {
    return [];
  }
  if (f.value.rawValue === "") {
    return [0];
  }
  if (excludedTermTypes.includes(f.value.type)) {
    return [];
  }
  const widget = removeWidgetVersionNumber(f.widget);
  if (widget === "com.moreapps:search") {
    const searchResult = f.value.rawValue as DataSourceEntry;
    const pathElements = term?.split(".");
    const value = traversePath(pathElements.slice(1).join("."), searchResult?.data ?? {});
    return value ? [value] : [];
  }
  if (widget === "com.moreapps.plugins:catalogue" && f.value.rawValue) {
    const result = f.value.rawValue as CatalogueResult;
    return [result.priceIncVat];
  }

  if (f.value.type === "object") {
    const pathElements = term?.split(".");
    const value = traversePath(pathElements.slice(1).join("."), f.value.rawValue ?? {});
    return value ? [value] : [];
  }
  if (f.value.type === "duration") {
    // It's stored in the registration data as hours in decimal, so convert to that number
    const duration = f.value.rawValue as DurationResult;
    return duration?.duration ? [Duration.fromISO(duration?.duration).as("hours")] : [];
  }
  if (f.value.type === "datetime") {
    const dateTime = f.value.rawValue as DateTimeValue;
    return dateTime ? [`${dateTime.date} ${dateTime.time}`] : [];
  }
  if (f.value.type === "currency" && f.value.rawValue) {
    const result = f.value.rawValue as CurrencyResult;
    return [result.value ?? 0];
  }
  if (widget === "com.moreapps:lookup") {
    if (typeof f.value.rawValue === "string") {
      return [f.value.rawValue];
    }
    const data = f.value.rawValue as string[];
    if (data && data.length > 0) {
      return data;
    }
    return [];
  }

  return [f.value.rawValue];
};

const isNestedField = (
  nestedField: FieldState<WidgetProperties, WidgetResult<unknown>>,
  subformFields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
): boolean =>
  subformFields?.some((subformWidget) => {
    const isParent = subformWidget.uniqueFieldId === nestedField.value.meta.parentId;
    if (!isParent) {
      return false;
    }

    // Only return fields that belong to a parent with an entry that hasn't been deleted
    return (subformWidget.value.entries || []).some(
      (entry) => entry.id === nestedField.value.meta.entryId && !entry.deleted,
    );
  });

const findTerm = (terms: string[], field: FieldState<WidgetProperties, WidgetResult<unknown>>): string | undefined =>
  terms.find((t) => t.split(".")[0] === field.value.meta.formFieldId);
