import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { Radio } from "../../storybook/components/Radio/Radio";
import { Icon } from "../../storybook/components/Icon/Icon";
import WidgetContainer from "../WidgetContainer";

export interface WidgetRatingProperties {
  required: boolean;
  label_text: string;
  rateScale: number;
}

const WidgetRating: Widget<WidgetRatingProperties, WidgetResult<number>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();

  return (
    <WidgetContainer fieldState={fieldState} name="RATING_FIELD">
      <Radio
        name={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        layout="horizontal"
        activeStyle={false}
        scroll
        value={fieldState.value.rawValue?.toString()}
        errorMessage={fieldState.error}
        disabled={readOnly}
        clearLabel={t("CLEAR")}
        onClear={() => setFieldState(undefined)}
        options={[...Array(Math.ceil(fieldState.properties.rateScale))].map((key, i) => ({
          label: {
            kind: "image",
            image: (
              <Icon
                name="StarIcon"
                type={i < (fieldState.value.rawValue || 0) ? "solid" : "outline"}
                className={classNames("size-8", {
                  "text-yellow-500": !readOnly,
                  "text-gray-500": readOnly,
                })}
              />
            ),
          },
          value: (i + 1).toString(),
        }))}
        required={fieldState.properties.required}
        onChange={(e) => setFieldState(parseInt(e.target.value, 10))}
      />
    </WidgetContainer>
  );
};

WidgetRating.defaultValue = (_properties, defaultMeta): WidgetResult<number> => ({
  type: "number",
  meta: {
    widget: "rating",
    ...defaultMeta,
  },
});

WidgetRating.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetRating;
