import { FC, MutableRefObject, PropsWithChildren } from "react";
import classNames from "classnames";

export type LabelProps = {
  id?: string;
  htmlFor?: string;
  label: string;
  required?: boolean;
  onClear?: () => void;
  showClearBtn?: boolean;
  clearLabel?: string;
  className?: string;
};

export const Label: FC<PropsWithChildren<LabelProps>> = ({
  id,
  htmlFor,
  label,
  required = false,
  onClear = (): void => {},
  showClearBtn = false,
  clearLabel = "clear",
  className,
}) => {
  const classes = classNames("mb-1 flex justify-between gap-x-1 file:items-start", className);

  return (
    <div className={classes}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label id={id} htmlFor={htmlFor} className="overflow-hidden">
        <Content label={label} required={required} />
      </label>
      {showClearBtn && <ClearButton label={clearLabel} onClear={onClear} />}
    </div>
  );
};

export type LegendProps = Omit<LabelProps, "htmlFor"> & {
  clickFocus: MutableRefObject<HTMLElement | null>;
};

export const Legend: FC<PropsWithChildren<LegendProps>> = ({
  id,
  label,
  clickFocus,
  required = false,
  onClear = (): void => {},
  showClearBtn,
  clearLabel = "clear",
  className,
  children,
}): JSX.Element => {
  const classes = classNames("min-w-0", className);

  return (
    <fieldset aria-labelledby={id} className={classes}>
      <div className="mb-1 flex items-center justify-between gap-x-1">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
        <legend
          id={id}
          onClick={() => clickFocus.current?.focus({ preventScroll: true })}
          className="w-full overflow-hidden"
        >
          <Content label={label} required={required} />
        </legend>
        {showClearBtn && <ClearButton label={clearLabel} onClear={onClear} />}
      </div>
      {children}
    </fieldset>
  );
};

type ContentProps = {
  label: string;
  required: boolean;
};
const Content = ({ label, required }: ContentProps): JSX.Element => (
  <div className="cursor-default break-words text-sm font-semibold">
    <span className="text-gray-700">{label}</span>
    {required && <span className="ml-1 text-red-600">*</span>}
  </div>
);

type ClearButtonProps = {
  label: string;
  onClear: () => void;
};
const ClearButton = ({ label, onClear }: ClearButtonProps): JSX.Element => (
  <button
    type="button"
    onClick={onClear}
    className="flex select-none items-center rounded px-0.5 text-brand-600 outline-none focus-visible:ring active:!text-gray-900 pointer:hover:text-brand-800"
  >
    <span className="text-sm font-semibold lowercase">{label}</span>
  </button>
);
