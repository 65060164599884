import { useTranslation } from "react-i18next";
import { useMemo, useRef, useState } from "react";
import { CameraSource } from "@capacitor/camera";
import axios from "axios";
import { useWindowSize } from "usehooks-ts";
import { isNil } from "lodash-es";
import { FileResult, PinIcon, PinResultMeta, Widget } from "../../types/Widget";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import logger from "../../utils/logger";
import useFileHandler from "../../hooks/useFileHandler";
import uuidv4 from "../../utils/uuid";
import { SubformEntry } from "./WidgetSubform";
import { validateUpload } from "../../utils/validationUtil";
import useResource from "../../hooks/useResource";
import { UploadStatus, WidgetResult } from "../../types/Field";
import { SubForm } from "../../types/FormVersion";
import useDatasourceImages from "../../hooks/useDatasourceImages";
import DatasourceImageSelect from "./search/DatasourceImageSelect";
import Img from "../Img";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import PinAddDrawer from "./pin/PinAddDrawer";
import PinMoveDrawer from "./pin/PinMoveDrawer";
import PinPreviewImage from "./pin/PinPreviewImage";
import PinEntries from "./pin/PinEntries";
import PinFormDrawer from "./pin/PinFormDrawer";
import { getFormVersion } from "../../utils/pinUtil";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { noopAsync } from "../../utils/noop";
import usePhotoHandler from "../../hooks/usePhotoHandler";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { DropdownMenu } from "../../storybook/components/DropdownMenu/DropdownMenu";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Label } from "../../storybook/components/Label/Label";
import PdfImageUpload, { ConvertStatus, PdfImageUploadMethods } from "./pin/PdfImageUpload";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import FileUploadFailedModal from "../FileUploadFailedModal";
import WidgetContainer from "../WidgetContainer";
import FileFeedback from "./file/FileFeedback";
import useEntries from "../../state/useEntries";
import useActionRemoveEntry from "../../state/useActionRemoveEntry";
import useActionGetFirstVisibleInvalidFieldId from "../../state/useActionGetFirstVisibleInvalidFieldId";
import useStateSubmissionId from "../../state/useStateSubmissionId";
import useStateFieldProperties from "../../state/useStateFieldProperties";
import useActionSetFocussedField from "../../state/useActionSetFocussedField";
import useActionRememberFields from "../../state/useActionRememberFields";
import useActionValidateForm from "../../state/useActionValidateForm";
import { preloadImage } from "../../utils/fileUtil";
import useFieldUploadState from "../../hooks/useFieldUploadState";

export interface PinConfig {
  form?: SubForm;
  target_form_id?: string;
  icon: PinIcon;
  itemMarkup?: string;
  name?: string;
}

export interface WidgetPinProperties {
  required: boolean;
  label_text: string;
  default_image?: string;
  allow_change_image?: boolean;
  pins?: PinConfig[];
}

const WidgetPin: Widget<WidgetPinProperties, WidgetResult<FileResult>> = ({ fieldState, setFieldState, readOnly }) => {
  const { t } = useTranslation();
  const removeEntry = useActionRemoveEntry();
  const getFirstVisibleInvalidFieldId = useActionGetFirstVisibleInvalidFieldId();
  const submissionId = useStateSubmissionId();
  const fieldProperties = useStateFieldProperties();
  const setFocussedField = useActionSetFocussedField();
  const rememberFields = useActionRememberFields();
  const validateForm = useActionValidateForm();
  const [entries, activeEntry, setActiveEntry] = useEntries(fieldState);
  const [pinViewOpen, setPinViewOpen] = useState(false);
  const [movePin, setMovePin] = useState<SubformEntry<PinResultMeta>>();
  const [imgUrl, setImgUrl] = useState<string>();
  const pdfRef = useRef<PdfImageUploadMethods>(null);
  const [convertState, setConvertState] = useState<ConvertStatus>("pending");
  const { isOnline } = useOnlineStatus();
  const images = useDatasourceImages(submissionId);
  const [selectImageDrawer, setSelectImageDrawer] = useState(false);
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const [showFailedUploadModal, setFailedUploadModal] = useState(false);
  const { storeFileUri, getFileUrl } = useFileHandler();
  const { addPhoto } = usePhotoHandler();
  const { isUploading, uploadPercentage } = useFieldUploadState(fieldState);
  const { resource, errorResource, isLoadingResource } = useResource(fieldState.properties.default_image);
  const windowSize = useWindowSize();

  const persistWithUploadStatus = (
    fileResult: FileResult,
    uploadStatus: UploadStatus,
    humanEdited: boolean = true,
  ): void => {
    setFieldState(fileResult, { uploadStatus, humanEdited });
  };

  const retryUpload = (): void => {
    setFieldState(fieldState.value.rawValue, { uploadStatus: "uploading" });
  };

  const pins = fieldState.properties.pins ?? [];

  useAsyncEffect(
    async () => {
      if (fieldState.value.rawValue?.id) {
        const fileUrl = await getFileUrl(fieldState.value.rawValue, submissionId);
        const src = await preloadImage(fileUrl); // avoids flickering while loading remote image
        setImgUrl(src);
        return;
      }
      if (!isLoadingResource && resource?.dataUrl) {
        setImgUrl(resource.dataUrl);
        const { fileResult, uploadStatus } = await storeFileUri(resource.dataUrl, submissionId, uuidv4());
        persistWithUploadStatus(fileResult, uploadStatus, false);
      }
    },
    noopAsync,
    [fieldState.value.rawValue?.remoteId, resource, isLoadingResource],
  );

  const getOptionalSelectImageButton = useMemo(
    () => (images.length > 0 ? [{ label: t("SELECT_PHOTO"), onClick: () => setSelectImageDrawer(true) }] : []),
    [images], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getOptionalUploadPdfButton = useMemo(
    () => (isOnline ? [{ label: t("UPLOAD_PDF"), onClick: () => pdfRef.current?.onClick() }] : []),
    [isOnline], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (fieldState.properties.default_image && isLoadingResource) {
    return <Spinner />;
  }

  if (errorResource || (resource && resource.dataUrl?.length === 0)) {
    return <Img className="mx-auto" src="/assets/image_error.svg" alt={t("IMAGE_FAILED_TO_LOAD_ALT")} />;
  }

  const edit = (pin: SubformEntry<any>): void => {
    // It has been saved before, otherwise it wouldn't appear in the Pin entry list.
    // Consider it a humanEdited entry to avoid deleting entry when entry is empty.
    setActiveEntry({ id: pin.id, isHumanEdited: true });
  };

  const handleAddPhoto = async (source: CameraSource): Promise<void> => {
    try {
      const { webPath, fileResult, uploadStatus } = await addPhoto({ source });
      setImgUrl(webPath);
      persistWithUploadStatus(fileResult, uploadStatus);
    } catch (error: any) {
      if (error instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      if (error.message !== "USER_CANCELLED") {
        logger.error("Failed to add Pin-photo", error);
      }
    }
  };

  const addSelectedImage = async (url: string): Promise<void> => {
    if (!url) {
      return;
    }
    const { data: blob } = await axios.get(url, { responseType: "blob" });
    const dataUrl = URL.createObjectURL(blob);
    const id = uuidv4();
    const { fileResult, uploadStatus } = await storeFileUri(dataUrl, submissionId, id);
    URL.revokeObjectURL(dataUrl);
    if (fieldState.value) {
      persistWithUploadStatus(fileResult, uploadStatus);
    }
  };

  const emptyState = (
    <>
      <DropdownMenu
        className="w-full"
        menuButton={() => (
          <IconAndTextButton
            block
            disabled={readOnly}
            label={!readOnly ? t("ADD_AN_IMAGE") : t("NO_IMAGE_ADDED")}
            icon="CameraIcon"
          />
        )}
        items={[
          { label: t("CAPTURE_PHOTO"), onClick: () => handleAddPhoto(CameraSource.Camera) },
          { label: t("BROWSE_PHOTOS"), onClick: () => handleAddPhoto(CameraSource.Photos) },
          ...getOptionalUploadPdfButton,
          ...getOptionalSelectImageButton,
        ]}
        disabled={readOnly}
      />
      <PdfImageUpload
        submissionId={submissionId}
        persistWithUploadStatus={persistWithUploadStatus}
        ref={pdfRef}
        setConvertState={setConvertState}
      />
    </>
  );

  const activeEntryFormId = entries.find((entry) => entry.id === activeEntry?.id)?.meta.scope.target;

  const closeForm = async (
    entryId: string,
    formVersion?: SubForm,
    discard?: boolean,
    enforceValidation?: boolean,
  ): Promise<void> => {
    validateForm(entryId);
    const firstVisibleInvalidFieldId = getFirstVisibleInvalidFieldId(entryId);

    if (isNil(firstVisibleInvalidFieldId) && !discard) {
      rememberFields(formVersion, entryId);
    }

    if (discard || !enforceValidation || isNil(firstVisibleInvalidFieldId)) {
      setActiveEntry(undefined);
      return;
    }

    setFocussedField(undefined); // reset focus
    setTimeout(() => setFocussedField({ uniqueFieldId: firstVisibleInvalidFieldId, entryId })); // ensure reset is done first
  };

  const removeActiveEntry = (): void => removeEntry(activeEntry!.id, fieldState.uniqueFieldId);

  const formDrawer = (
    <PinFormDrawer
      label={fieldState.properties.label_text}
      formVersion={getFormVersion(activeEntryFormId, pins, fieldProperties)}
      submissionId={submissionId}
      parentId={fieldState.uniqueFieldId}
      activeEntry={activeEntry}
      closeForm={closeForm}
      removeEntry={removeActiveEntry}
    />
  );

  return (
    <WidgetContainer fieldState={fieldState} name="PIN_FIELD">
      <Label
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
      />
      <DatasourceImageSelect
        open={selectImageDrawer}
        setOpen={setSelectImageDrawer}
        images={images}
        onSelect={addSelectedImage}
      />
      {!imgUrl && (convertState === "pending" || convertState === "error") ? (
        emptyState
      ) : (
        <>
          <PinPreviewImage
            imgUrl={imgUrl}
            entries={entries}
            uploadStatus={fieldState.value.meta.uploadStatus}
            isDisabled={readOnly}
            windowSize={windowSize}
            onClick={() => !readOnly && setPinViewOpen(true)}
            isUploadingPhoto={isUploading || convertState === "converting" || convertState === "uploading"}
            uploadPercentage={uploadPercentage}
            onUploadRetry={() => setFailedUploadModal(true)}
          />
          <PinEntries
            entries={entries}
            isDisabled={readOnly}
            onDelete={(intent) => removeEntry(intent.id, fieldState.uniqueFieldId)}
            onEdit={edit}
            onMove={setMovePin}
          />
          <FileFeedback fieldState={fieldState} />
          <PinAddDrawer
            uniqueFieldId={fieldState.uniqueFieldId}
            entries={entries}
            pins={pins}
            widgetProps={fieldState.properties}
            open={pinViewOpen}
            onClose={() => setPinViewOpen(false)}
            onUpload={persistWithUploadStatus}
            submissionId={submissionId}
            windowSize={windowSize}
            imgUrl={imgUrl}
            addSelectedImage={addSelectedImage}
            setActiveEntry={setActiveEntry}
          >
            {formDrawer}
          </PinAddDrawer>
          <PinMoveDrawer
            uniqueFieldId={fieldState.uniqueFieldId}
            entry={movePin}
            onClose={() => setMovePin(undefined)}
            imgUrl={imgUrl}
          />
          {!pinViewOpen && formDrawer}
        </>
      )}
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
      {showFailedUploadModal && (
        <FileUploadFailedModal
          show={showFailedUploadModal}
          onClose={() => setFailedUploadModal(false)}
          onRetry={retryUpload}
        />
      )}
    </WidgetContainer>
  );
};

WidgetPin.defaultValue = (_properties, defaultMeta: any): WidgetResult<FileResult> => ({
  type: "file",
  meta: {
    widget: "pin",
    ...defaultMeta,
  },
  entries: [],
});

WidgetPin.validate = (val, _properties, t, meta, entries): string | undefined => {
  const subformEntries = entries?.filter((entry) => !entry.deleted) || [];
  const hasEntryWithError = !isNil(subformEntries.find((x) => x.meta.error));
  if (hasEntryWithError) {
    return t("VALIDATION_SUBFORM_ENTRY_INVALID");
  }

  return validateUpload(val, meta.uploadStatus, t);
};

export default WidgetPin;
