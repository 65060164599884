import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { merge } from "lodash-es";
import Form, { SubmissionFormData } from "./Form";
import { submissionFormDataToFields } from "../types/Field";
import { buildFormState } from "../state/useFieldState";
import { useSubmissionStoreInitializer } from "../state/useSubmissionStoreInitializer";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import { FormVersion } from "../types/FormVersion";
import { getStaticDefaultValues } from "../utils/submissionUtil";
import useDeviceInfo from "../hooks/useDeviceInfo";
import { SubmissionStoreOptions } from "../state/useSubmissionStore";

interface StaticFormProps {
  formVersion: FormVersion;
  submissionId: string;
  data?: SubmissionFormData;
  padding?: boolean;
  readOnly?: boolean;
}

// these constants eliminate re-renders, because an inline object is seen as "new" each render
const editableOptions: SubmissionStoreOptions = { readOnly: false, persist: false, validate: false };
const readOnlyOptions: SubmissionStoreOptions = { readOnly: true, persist: false, validate: false };
const emptyData = {};

const StaticForm: FC<StaticFormProps> = ({
  formVersion,
  data = emptyData,
  padding = false,
  readOnly = false,
  submissionId,
}) => {
  const { formId } = formVersion;
  const { id: deviceId } = useDeviceInfo();
  const formData = useMemo(
    () => merge(getStaticDefaultValues(formVersion, submissionId), data),
    [data, formVersion, submissionId],
  );
  const formState = useMemo(
    () => buildFormState(formVersion, submissionFormDataToFields(formData), submissionId, deviceId, formData),
    [formData, formVersion, submissionId, deviceId],
  );
  const { initialized } = useSubmissionStoreInitializer(
    submissionId,
    formId,
    formState,
    formVersion,
    readOnly ? readOnlyOptions : editableOptions,
  );

  if (!initialized) {
    return <Spinner />;
  }

  return (
    <div className={classNames("flex flex-col gap-y-6", { "px-4 py-6": padding })}>
      <Form formVersion={formVersion} submissionId={submissionId} />
    </div>
  );
};
export default StaticForm;
