import useSubmissionStore, { FormState, SubmissionStoreOptions } from "./useSubmissionStore";
import { AbstractForm, FieldProperties } from "../types/FormVersion";
import { FormEngine } from "../utils/FormEngine";

const useActionInitialize = (): ((
  formVersion: AbstractForm,
  formEngine: FormEngine,
  fieldProperties: FieldProperties,
  submissionId: string,
  deviceId: string,
  formId: string,
  userId: string | undefined,
  options: SubmissionStoreOptions,
  initialFormState: FormState,
) => void) => useSubmissionStore((store) => store.initialize);

export default useActionInitialize;
